define("ember-data-github/serializers/github-repository-contents", ["exports", "ember-data-github/serializers/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    extractId(modelClass, resourceHash) {
      return resourceHash.url;
    },
    modelNameFromPayloadKey() {
      return 'github-repository-contents';
    }
  });
});