define("ember-changeset-validations/validators/length", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-validators"], function (_exports, _validationErrors, _withDefaults, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateLength;
  function validateLength(options = {}) {
    options = (0, _withDefaults.default)(options, {
      useBetweenMessage: true
    });
    return (key, value) => {
      let result = (0, _emberValidators.validate)('length', value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});