define("@heroku/ember-hk-components/components/async-button", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let positionalParamsMixin = {
    positionalParams: 'params'
  };
  const ButtonComponent = _component.default.extend(positionalParamsMixin, {
    tagName: 'button',
    textState: 'default',
    asyncState: _object.computed.alias('default'),
    reset: false,
    classNames: ['async-button'],
    classNameBindings: ['textState'],
    attributeBindings: ['disabled', 'type', '_href:href', 'tabindex'],
    type: 'submit',
    disabled: (0, _object.computed)('textState', 'disableWhen', function () {
      let textState = (0, _object.get)(this, 'textState');
      let disableWhen = (0, _object.get)(this, 'disableWhen');
      return disableWhen || textState === 'pending';
    }),
    click() {
      let params = (0, _object.getWithDefault)(this, 'params', []);
      let callbackHandler = promise => {
        (0, _object.set)(this, 'promise', promise);
      };
      this.action(callbackHandler, ...params);
      (0, _object.set)(this, 'textState', 'pending');

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    },
    text: (0, _object.computed)('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected', function () {
      return (0, _object.getWithDefault)(this, this.textState, (0, _object.get)(this, 'default'));
    }),
    /* eslint-disable ember/no-observers */
    resetObserver: (0, _object.observer)('textState', 'reset', function () {
      let states = ['resolved', 'rejected', 'fulfilled'];
      let found = false;
      let textState = (0, _object.get)(this, 'textState');
      for (let idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }
      if ((0, _object.get)(this, 'reset') && found) {
        (0, _object.set)(this, 'textState', 'default');
      }
    }),
    handleActionPromise: (0, _object.observer)('promise', function () {
      let promise = (0, _object.get)(this, 'promise');
      if (!promise) {
        return;
      }
      promise.then(() => {
        if (!this.isDestroyed) {
          (0, _object.set)(this, 'textState', 'fulfilled');
        }
      }).catch(() => {
        if (!this.isDestroyed) {
          (0, _object.set)(this, 'textState', 'rejected');
        }
      });
    }),
    /* eslint-enable ember/no-observers */

    setUnknownProperty(key, value) {
      this[key] = null;
      this.set(key, value);
    },
    _href: (0, _object.computed)('href', function () {
      let href = (0, _object.get)(this, 'href');
      if (href) {
        return href;
      }
      let tagName = (0, _object.get)(this, 'tagName').toLowerCase();
      if (tagName === 'a' && href === undefined) {
        return '';
      }
    }),
    _stateObject: (0, _object.computed)('textState', function () {
      let textState = (0, _object.get)(this, 'textState');
      let isFulfilled = textState === 'fulfilled' || textState === 'resolved';
      return {
        isPending: textState === 'pending',
        isFulfilled,
        isResolved: isFulfilled,
        isRejected: textState === 'rejected',
        isDefault: textState === 'default'
      };
    })
  });
  var _default = _exports.default = ButtonComponent;
});