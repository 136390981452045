define("ember-data-github/models/github-file", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    additions: (0, _attr.default)('number'),
    blobUrl: (0, _attr.default)('string'),
    changes: (0, _attr.default)('number'),
    deletions: (0, _attr.default)('number'),
    filename: (0, _attr.default)('string'),
    patch: (0, _attr.default)('string'),
    rawUrl: (0, _attr.default)('string'),
    sha: (0, _attr.default)('string'),
    status: (0, _attr.default)('string')
  });
});