define("ember-data-github/models/github-user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "@ember/application/deprecations", "@ember/object"], function (_exports, _model, _attr, _relationships, _deprecations, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    avatarUrl: (0, _attr.default)('string'),
    bio: (0, _attr.default)('string'),
    blog: (0, _attr.default)('string'),
    company: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    gravatarId: (0, _attr.default)('string'),
    location: (0, _attr.default)('string'),
    login: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    followers: (0, _attr.default)('number'),
    following: (0, _attr.default)('number'),
    publicGists: (0, _attr.default)('number'),
    publicRepos: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    hireable: (0, _attr.default)('boolean'),
    siteAdmin: (0, _attr.default)('boolean'),
    // Urls
    eventsUrl: (0, _attr.default)('string'),
    followersUrl: (0, _attr.default)('string'),
    followingUrl: (0, _attr.default)('string'),
    gistsUrl: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    organizationsUrl: (0, _attr.default)('string'),
    receivedEventsUrl: (0, _attr.default)('string'),
    reposUrl: (0, _attr.default)('string'),
    starredUrl: (0, _attr.default)('string'),
    subscriptionsUrl: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    // Embedded Objects
    repositories: (0, _relationships.hasMany)('github-repository'),
    githubRepositories: (0, _object.computed)('repositories.[]', function () {
      (false && !(false) && (0, _deprecations.deprecate)('The githubRepositories property on the github-user model has been deprecated.  Please use the repositories property.', false, {
        id: 'ember-data-github.deprecated-model-props',
        until: '1.0.0'
      }));
      return this.get('repositories');
    })
  });
});