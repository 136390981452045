define("torii/redirect-handler", ["exports", "rsvp", "@ember/object", "@ember/error", "torii/mixins/ui-service-mixin", "torii/configuration"], function (_exports, _rsvp, _object, _error, _uiServiceMixin, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ToriiRedirectError = void 0;
  /**
   * RedirectHandler will attempt to find
   * these keys in the URL. If found,
   * this is an indication to Torii that
   * the Ember app has loaded inside a popup
   * and should postMessage this data to window.opener
   */

  class ToriiRedirectError extends _error.default {
    constructor() {
      super(...arguments);
      this.name = 'ToriiRedirectError';
    }
  }
  _exports.ToriiRedirectError = ToriiRedirectError;
  var RedirectHandler = _object.default.extend({
    run() {
      var windowObject = this.windowObject;
      return new _rsvp.Promise(function (resolve, reject) {
        var pendingRequestKey = windowObject.localStorage.getItem(_uiServiceMixin.CURRENT_REQUEST_KEY);
        windowObject.localStorage.removeItem(_uiServiceMixin.CURRENT_REQUEST_KEY);
        if (pendingRequestKey) {
          var url = windowObject.location.toString();
          windowObject.localStorage.setItem(_uiServiceMixin.WARNING_KEY, 'true');
          windowObject.localStorage.setItem(pendingRequestKey, url);
          var remoteServiceName = _configuration.default.remoteServiceName || 'popup';
          if (remoteServiceName === 'popup') {
            // NOTE : If a single provider has been configured to use the 'iframe'
            // service, this next line will still be called. It will just fail silently.
            windowObject.close();
          }
        } else {
          reject(new ToriiRedirectError('Not a torii popup'));
        }
      });
    }
  });
  RedirectHandler.reopenClass({
    // untested
    handle(windowObject) {
      var handler = RedirectHandler.create({
        windowObject: windowObject
      });
      return handler.run();
    }
  });
  var _default = _exports.default = RedirectHandler;
});