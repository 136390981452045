define("@heroku/ember-hk-components/components/hk-slide-panel/breakout", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/breakout", "rsvp"], function (_exports, _component, _breakout, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _breakout.default,
    classNames: ['hk-slide-panel-breakout'],
    attributeBindings: ['hidden'],
    'data-test-target': 'slide-panel-breakout',
    onClickOverlay: () => {},
    hidden: false,
    _beforeDestroyElement: null,
    dismiss() {
      this.set('hidden', true);
      return new _rsvp.Promise((resolve, reject) => {
        const {
          element
        } = this;
        const event = 'animationend';
        const options = {
          once: true
        };
        const complete = () => {
          this._beforeDestroyElement = null;
          resolve();
        };
        const cancel = () => {
          element.removeEventListener(event, complete, options);
          reject();
        };
        element.addEventListener(event, complete, options);
        this._beforeDestroyElement = cancel;
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this._beforeDestroyElement) {
        this._beforeDestroyElement();
      }
    }
  });
});