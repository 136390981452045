define("ember-data-github/adapters/github-member", ["exports", "ember-data-github/adapters/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    urlForQuery(query) {
      const org = query.org;
      delete query.org;
      return `${this.get('host')}/orgs/${org}/members`;
    }
  });
});