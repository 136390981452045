define("ember-data-github/serializers/github-compare", ["exports", "ember-data", "ember-data-github/serializers/github"], function (_exports, _emberData, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;
  var _default = _exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      commits: {
        embedded: 'always'
      },
      files: {
        embedded: 'always'
      },
      baseCommit: {
        embedded: 'always'
      },
      mergeBaseCommit: {
        embedded: 'always'
      }
    },
    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.diff_url;
      return this._super(modelClass, resourceHash, prop);
    },
    extractRelationships(modelClass, resourceHash) {
      resourceHash.base_commit.type = 'github-commit';
      resourceHash.base_commit.id = resourceHash['base_commit']['sha'];
      resourceHash.merge_base_commit.type = 'github-commit';
      resourceHash.merge_base_commit.id = resourceHash['merge_base_commit']['sha'];
      resourceHash.commits.forEach(commit => {
        commit.type = 'github-commit';
        commit.id = commit.sha;
      });
      resourceHash.files.forEach(file => {
        file.type = 'github-file';
        file.id = file.sha;
      });
      return this._super(modelClass, resourceHash);
    }
  });
});