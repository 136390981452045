define("ember-sortable/templates/components/sortable-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "8MjUn9d3",
    "block": "[[[18,1,[[28,[37,1],null,[[\"handle\"],[[50,\"ember-sortable@sortable-handle\",0,null,null]]]]]],[1,\"\\n\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-item.hbs",
    "isStrictMode": false
  });
});