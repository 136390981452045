define("@heroku/ember-hk-components/templates/components/hk-input-with-validation-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WO97Y7TD",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"isValid\",\"isInvalid\",\"key-up\",\"id\",\"value\",\"name\",\"class\",\"autofocus\",\"disabled\",\"maxlength\",\"placeholder\",\"readonly\",\"aria-describedby\"],[[33,1],[33,2],[28,[37,3],[[30,0],\"onChange\"],null],[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10],[33,11],[28,[33,12],null,null],[33,13]]]]],[1,\"\\n\\n\"],[41,[33,15],[[[41,[33,16],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-light-silver br1 br--right bt br bb b--light-gray ph2\"],[14,\"data-test-target\",\"loading-icon\"],[12],[1,\"\\n        \"],[8,[39,17],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"loading-16\",\"malibu-fill-gradient-dark-gray\",16,\"Loading\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[33,1],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-green br1 br--right bt br bb b--light-green ph2\"],[14,\"data-test-target\",\"valid-icon\"],[12],[1,\"\\n        \"],[8,[39,17],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"success-badge-16\",\"malibu-fill-gradient-green\",16,[29,[[36,6],\" is valid\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-red br1 br--right bt br bb b--light-red ph2\"],[14,\"data-test-target\",\"invalid-icon\"],[12],[1,\"\\n        \"],[8,[39,17],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-badge-16\",\"malibu-fill-gradient-red\",16,[29,[[36,6],\" is invalid\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]],null],[13],[1,\"\\n\"]],[],false,[\"hk-input\",\"isValid\",\"isInvalid\",\"action\",\"id\",\"inputValue\",\"name\",\"inputClasses\",\"autofocus\",\"disabled\",\"maxlength\",\"placeholder\",\"readonly\",\"errorsListId\",\"if\",\"isShowingStatus\",\"isValidating\",\"malibu-icon\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-input-with-validation-status.hbs",
    "isStrictMode": false
  });
});