define("ember-data-github/adapters/github-repository-contents", ["exports", "ember-data-github/adapters/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    urlForQueryRecord(query) {
      const repo = query.repo;
      const file = query.file;
      delete query.repo;
      delete query.file;
      return `${this.get('host')}/repos/${repo}/contents/${file}`;
    }
  });
});