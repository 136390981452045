define("ember-data-github/serializers/github-user", ["exports", "ember-data-github/serializers/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (id === '#') {
        payload.repos_url = payload.repos_url.replace(`users/${payload.login}`, 'user');
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.recordId || resourceHash.login;
      resourceHash.links = {
        repositories: resourceHash.repos_url
      };
      return this._super(modelClass, resourceHash, prop);
    }
  });
});