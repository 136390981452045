define("ember-changeset-validations/utils/validation-errors", ["exports", "ember", "@ember/object", "ember-changeset-validations/utils/get-messages", "ember-get-config"], function (_exports, _ember, _object, _getMessages, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildMessage;
  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  const {
    assert,
    typeOf
  } = _ember.default;
  const assign = _ember.default.assign || _ember.default.merge;
  function buildMessage(key, result) {
    let returnsRaw = (0, _object.getWithDefault)(_emberGetConfig.default, 'changeset-validations.rawOutput', false);
    let messages = (0, _getMessages.default)();
    let description = messages.getDescriptionFor(key);
    if (result.message) {
      return result.message;
    }
    let {
      type,
      value,
      context = {}
    } = result;
    if (context.message) {
      let message = context.message;
      if (typeOf(message) === 'function') {
        let builtMessage = message(key, type, value, context);
        assert('Custom message function must return a string', typeOf(builtMessage) === 'string');
        return builtMessage;
      }
      return messages.formatMessage(message, assign({
        description
      }, context));
    }
    let message = (0, _object.get)(messages, type);
    if (returnsRaw) {
      context = assign({}, context, {
        description
      });
      return {
        value,
        type,
        message,
        context
      };
    } else {
      return messages.formatMessage(message, assign({
        description
      }, context));
    }
  }
});