define("@heroku/ember-hk-components/components/hk-well/headline", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-well/headline"], function (_exports, _component, _headline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _headline.default,
    classNames: ['f3 dark-gray lh-title mv1'],
    'data-test-target': 'hk-well-headline'
  });
});