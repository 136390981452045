define("hk-components/mixins/modal-behavior", ["exports", "jquery", "@ember/object/mixin", "@ember/runloop"], function (_exports, _jquery, _mixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isModalTransitioning = isModalTransitioning;
  const ESC_KEY = 27;
  let transitioning = false;
  var _default = _exports.default = _mixin.default.create({
    destinationElementId: 'modal-overlays',
    targetAttachment: 'top',
    // set this to null to work around Ember 2.18 regression
    // https://github.com/emberjs/ember.js/issues/15966
    target: null,
    didInsertElement() {
      this._super(...arguments);
      this._trackOpen();
      this._initEscListener();
      (0, _jquery.default)('body').addClass('modal-is-open');
    },
    _trackOpen() {
      startTransitionTracking();
      (0, _jquery.default)(document).one('animationend', '.ember-modal-dialog', endTransitionTracking);
    },
    _initEscListener() {
      const closeOnEscapeKey = ev => {
        if (ev.keyCode === ESC_KEY) {
          this._closeModal();
        }
      };
      window.addEventListener('keydown', closeOnEscapeKey);
      this.on('willDestroyElement', () => {
        window.removeEventListener('keydown', closeOnEscapeKey);
      });
    },
    _closeModal() {
      startTransitionTracking();
      this.set('isClosing', true);
      const close = () => {
        endTransitionTracking();
        if (this.cancelAction) {
          this.cancelAction();
        }
        if (this.onClose) {
          this.onClose();
        }
      };
      _runloop.run.later(close, 100);
    },
    willDestroy() {
      (0, _jquery.default)('body').removeClass('modal-is-open');
      endTransitionTracking();
      this._super(...arguments);
    },
    actions: {
      closeModalFromOverlay() {
        if (this.get('clickOverlayToClose') !== false) {
          this._closeModal();
        }
      }
    }
  });
  function isModalTransitioning() {
    return transitioning;
  }
  function startTransitionTracking() {
    transitioning = true;
  }
  function endTransitionTracking() {
    transitioning = false;
  }
});