define("ember-changeset/utils/includes", ["exports", "@ember/array", "@ember/debug", "@ember/utils"], function (_exports, _array, _debug, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includes;
  // @flow

  function includes /*:: <T> */(arr /*: Array<T> */, ...args /*: Array<T> */) /*: boolean */{
    (false && !((0, _utils.typeOf)(arr) === 'array') && (0, _debug.assert)('must be array', (0, _utils.typeOf)(arr) === 'array'));
    let wrapped = (0, _array.A)(arr);
    let inclusionFn = wrapped.includes || wrapped.contains;
    return inclusionFn.apply(arr, args);
  }
});