define("ember-changeset/utils/assign", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pureAssign;
  // @flow

  function pureAssign(...objects /*: Array<Object> */) /*: Object */{
    return (0, _polyfills.assign)({}, ...objects);
  }
});