define("@heroku/ember-hk-components/components/hk-inline-edit", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/runloop", "@heroku/ember-hk-components/templates/components/hk-inline-edit", "@ember/object/internals", "@ember/debug"], function (_exports, _rsvp, _component, _object, _runloop, _hkInlineEdit, _internals, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    empty,
    not,
    oneWay,
    readOnly
  } = _object.computed;
  const HKInlineEdit = _component.default.extend({
    layout: _hkInlineEdit.default,
    tagName: 'hk-inline-edit',
    isEditing: false,
    allowBlank: true,
    deferredValue: oneWay('value'),
    emptyText: 'Click to enter text',
    displayValue: (0, _object.computed)('value', 'emptyText', function () {
      return this.get('value') || this.get('emptyText');
    }),
    labelWithAssert: readOnly('label', function () {
      (false && !(this.label) && (0, _debug.assert)('label is required to hk-inline-edit', this.label));
      return this.label;
    }),
    inputId: (0, _object.computed)(function () {
      /* generate random guidId by default */
      return (0, _internals.guidFor)({});
    }),
    isEditingDisabled: false,
    valueIsBlank: empty('deferredValue'),
    valueHasntChanged: (0, _object.computed)('value', 'deferredValue', 'allowBlank', function () {
      const valueNotChanged = this.get('value') === this.get('deferredValue');
      const valueIsBlank = this.get('valueIsBlank');
      if (this.get('allowBlank')) {
        return valueNotChanged;
      } else {
        return valueNotChanged || valueIsBlank;
      }
    }),
    valueHasChanged: not('valueHasntChanged'),
    isUpdateDisabled: oneWay('valueHasntChanged'),
    mouseDown(event1) {
      const isSubmitBtn = isSubmitButton(event1.target);
      const btnIsEnabled = event1.target.className.indexOf('disabled') === -1;

      // The intention of the below logic is to ensure that we don't treat a user clicking
      // submit the same as when the user clicks outside the input, i.e. the focusOut action

      // when the user clicks the "Update" button (_and it's active_)
      if (isSubmitBtn && btnIsEnabled) {
        // stash the current focusOut/focusIn
        const focusOut = this.get('focusOut');
        const focusIn = this.get('focusIn');

        // remove focusOut/focusIn as listeners
        this.set('focusOut', null); // because we don't want to revert/cancel changes!
        this.set('focusIn', null); // because the browser is giving focus back on click!

        // add a mouseUp listener
        this.set('mouseUp', event2 => {
          const isSubmitBtnOnUp = isSubmitButton(event2.target);
          if (!isSubmitBtnOnUp) {
            // re-add the focusIn/focusOut events if the mouseUp
            // is performed *outside* of the "Update" button
            // i.e. the user is not confirming their intent to change
            this.set('focusOut', focusOut);
            this.set('focusIn', focusIn);
          }

          // else `save` is triggered above and things happen, we'll rebind focus
          // events there

          // remove this mouseUp event
          this.set('mouseUp', null);
        });
      }

      //  http://i.kinja-img.com/gawker-media/image/upload/s--3HUSfHUr--/ktwsironnp83lum404wt.jpg
    },
    focusIn() {
      this.get('focusInHandler').call(this);
    },
    focusOut() {
      this.get('focusOutHandler').call(this);
    },
    focusOutHandler() {
      // Wrapped in run.later to ensure that other events have finished running
      // This ensures that document.activeElement is actually the element that was clicked
      _runloop.run.later(this, () => {
        const activeElement = document.activeElement;
        const el = this.element;
        if (el) {
          // Component lacks focus if the active element is not inside it or is not the active element
          const isChildNode = el.contains(activeElement);
          const isSameNode = el.isSameNode(activeElement);
          const isFocussedOut = !(isChildNode || isSameNode);

          // Allow the edit to be cancelled if the component loses focus and the input value has not changed
          if (isFocussedOut && activeElement.tagName.toLowerCase() !== 'a' && !this.get('valueHasChanged')) {
            this.send('cancelEdit');
          }
        }
      }, 0);
    },
    focusInHandler() {
      this.set('focusOut', this.get('focusOutHandler'));
    },
    actions: {
      valueChanged(valueHasChanged) {
        if (this.get('onValueChanged')) {
          this.get('onValueChanged')(valueHasChanged);
        }
      },
      save(deferredValue) {
        if (this.get('onSave')) {
          // if closure action we call returns a rejected promise
          // then we want to keep the input in the editing state
          // This is useful for confirm modal actions
          return (0, _rsvp.resolve)(this.get('onSave')(deferredValue)).then(() => {
            this.set('isEditing', false);
          }).finally(() => {
            // regardless of if the submit action suceeeds, re-bind the focusOut
            // handler so that it behaves like normal
            this.set('focusOut', this.get('focusOutHandler'));
            this.set('focusIn', this.get('focusInHandler'));
          });
        }
      },
      cancelEdit() {
        this.set('deferredValue', this.get('value'));
        this.set('isEditing', false);
      },
      edit() {
        this.set('isEditing', true);
        if (this.get('onEdit')) {
          this.get('onEdit')();
        }
      }
    }
  });
  (0, _debug.runInDebug)(function () {
    HKInlineEdit.reopen({
      isEditingDisabled: (0, _object.computed)(function () {
        throw new Error("ember-hk-components hk-inline-edit: isEditingDisabled was removed; please set isDisabled on hk-inline-edit instead.");
      })
    });
  });
  var _default = _exports.default = HKInlineEdit;
  function isSubmitButton(element) {
    return element.className.indexOf('__hk-inline-edit-submit-button__') !== -1;
  }
});