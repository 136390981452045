define("@heroku/ember-hk-components/components/hk-slide-panel/footer", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/footer"], function (_exports, _component, _footer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _footer.default,
    'data-test-target': 'slide-panel-footer',
    classNames: ['hk-slide-panel-footer', 'relative', 'shadow-outer-1', 'flex', 'justify-center', 'items-center', 'z-2', 'pa4']
  });
});