define("ember-svg-jar/utils/make-svg", ["exports", "@ember/polyfills", "@ember/utils", "@ember/string"], function (_exports, _polyfills, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeSvg;
  _exports.formatAttrs = formatAttrs;
  _exports.inlineSvgFor = inlineSvgFor;
  _exports.symbolUseFor = symbolUseFor;
  function formatAttrs(attrs) {
    return Object.keys(attrs).map(key => !(0, _utils.isNone)(attrs[key]) && `${key}="${attrs[key]}"`).filter(attr => attr).join(' ');
  }
  function symbolUseFor(assetId, attrs = {}) {
    return `<svg ${formatAttrs(attrs)}><use xlink:href="${assetId}" /></svg>`;
  }
  function inlineSvgFor(assetId, getInlineAsset, attrs = {}) {
    let asset = getInlineAsset(assetId);
    if (!asset) {
      // eslint-disable-next-line no-console
      console.warn(`ember-svg-jar: Missing inline SVG for ${assetId}`);
      return;
    }
    let svgAttrs = asset.attrs ? (0, _polyfills.assign)({}, asset.attrs, attrs) : attrs;
    let {
      size
    } = attrs;
    if (size) {
      svgAttrs.width = parseFloat(svgAttrs.width) * size || svgAttrs.width;
      svgAttrs.height = parseFloat(svgAttrs.height) * size || svgAttrs.height;
      delete svgAttrs.size;
    }
    return `<svg ${formatAttrs(svgAttrs)}>${asset.content}</svg>`;
  }
  function makeSvg(assetId, attrs = {}, getInlineAsset) {
    if (!assetId) {
      // eslint-disable-next-line no-console
      console.warn('ember-svg-jar: asset name should not be undefined or null');
      return;
    }
    let isSymbol = assetId.lastIndexOf('#', 0) === 0;
    let svg = isSymbol ? symbolUseFor(assetId, attrs) : inlineSvgFor(assetId, getInlineAsset, attrs);
    return (0, _string.htmlSafe)(svg);
  }
});