define("@heroku/ember-hk-components/templates/components/hk-slide-panel/breakout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "I0YOBrKR",
    "block": "[[[10,0],[14,0,\"hk-slide-panel-breakout-overlay\"],[14,\"data-test-target\",\"slide-panel-breakout-overlay\"],[15,\"onclick\",[28,[37,0],[[30,0],[33,1],[28,[37,0],[[30,0],[33,2]],null]],null]],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"hk-slide-panel-breakout-content shadow-outer-2 br1\"],[14,\"data-test-target\",\"slide-panel-breakout-content\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,4],null,[[\"dismiss\"],[[28,[37,0],[[30,0],[33,2]],null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"onClickOverlay\",\"dismiss\",\"yield\",\"hash\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/breakout.hbs",
    "isStrictMode": false
  });
});