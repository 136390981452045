define("@heroku/ember-hk-components/templates/components/hk-well", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "y7uiV9dv",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"headline\",\"body\"],[[50,\"hk-well/headline\",0,null,null],[50,\"hk-well/body\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[41,[33,5],[[[6,[39,6],null,null,[[\"default\"],[[[[1,\"      \"],[1,[34,5]],[1,\"\\n\"]],[]]]]]],[]],null],[41,[33,7],[[[6,[39,8],null,null,[[\"default\"],[[[[1,\"      \"],[1,[34,7]],[1,\"\\n\"]],[]]]]]],[]],null]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"headline\",\"hk-well/headline\",\"body\",\"hk-well/body\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-well.hbs",
    "isStrictMode": false
  });
});