define("ember-changeset/index", ["exports", "ember-changeset/-private/relay", "ember-changeset/utils/computed/object-to-array", "ember-changeset/utils/computed/is-empty-object", "ember-changeset/utils/computed/inflate", "ember-changeset/utils/computed/transform", "ember-changeset/utils/is-promise", "ember-changeset/utils/is-object", "ember-changeset/utils/assign", "ember-changeset/utils/includes", "ember-changeset/utils/take", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-relay", "ember-changeset/utils/set-nested-property", "ember-changeset/utils/merge-nested", "ember-changeset/utils/validate-nested-obj", "ember-changeset/utils/object-without", "ember-changeset/-private/err", "ember-changeset/-private/change", "ember-deep-set", "@ember/object", "@ember/object/evented", "@ember/array", "rsvp", "@ember/debug", "@ember/utils", "@ember/object/computed"], function (_exports, _relay, _objectToArray, _isEmptyObject, _inflate, _transform, _isPromise, _isObject, _assign, _includes, _take, _isChangeset, _isRelay, _setNestedProperty, _mergeNested, _validateNestedObj, _objectWithout, _err, _change, _emberDeepSet, _object, _evented, _array, _rsvp, _debug, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  // @flow

  /*::
  import type { ValidatorFunc } from 'ember-changeset/types/validator-func';
  import type {
    ValidationResult,
    ValidationErr,
  } from 'ember-changeset/types/validation-result';
  import type { RelayDef } from 'ember-changeset/-private/relay';
  import type { Config } from 'ember-changeset/types/config';
  import type { ErrLike } from 'ember-changeset/-private/err';
  */

  const {
    keys
  } = Object;
  const CONTENT = '_content';
  const CHANGES = '_changes';
  const ERRORS = '_errors';
  const RELAY_CACHE = '_relayCache';
  const VALIDATOR = '_validator';
  const OPTIONS = '_options';
  const RUNNING_VALIDATIONS = '_runningValidations';
  const BEFORE_VALIDATION_EVENT = 'beforeValidation';
  const AFTER_VALIDATION_EVENT = 'afterValidation';
  const AFTER_ROLLBACK_EVENT = 'afterRollback';
  const defaultValidatorFn = () => true;
  const defaultOptions = {
    skipValidate: false
  };

  /*::
  type Changes            = { [string]: Change   };
  type Errors             = { [string]: Err      };
  type RelayCache         = { [string]: RelayDef };
  type RunningValidations = { [string]: number   };
  
  type InternalMap =
    | Changes
    | Errors
    | RelayCache
    | RunningValidations;
  
  type NewProperty<T> = {
    key:       string,
    value:     T,
    oldValue?: mixed,
  };
  
  type InternalMapKey =
    | '_changes'
    | '_errors'
    | '_relayCache'
    | '_runningValidations';
  
  type Snapshot = {
    changes: { [string]: mixed          },
    errors:  { [string]: ErrLike<mixed> },
  };
  
  type Inflated<T> = {
    [string]: Inflated<T> | T,
  };
  
  export type ChangesetDef = {|
    _content:            Object,
    _changes:            Changes,
    _errors:             Errors,
    _relayCache:         RelayCache,
    _validator:          ValidatorFunc,
    _options:            Config,
    _runningValidations: RunningValidations,
    __changeset__:       '__CHANGESET__',
    _bareChanges:        { [string]: mixed },
  
    changes: Array<{ key: string }>,
    errors:  Array<{ key: string }>,
    change:  Inflated<mixed>,
    error:   Inflated<ErrLike<mixed>>,
    data:    Object,
  
    isValid:    boolean,
    isPristine: boolean,
    isInvalid:  boolean,
    isDirty:    boolean,
  
    _super: () => void,
    init: () => void,
    unknownProperty: (string) => mixed,
    get: (string) => mixed,
    _valueFor: (string, ?boolean) => RelayDef | mixed,
    _relayFor: (string, Object) => RelayDef,
    toString: () => string,
    _deleteKey: (InternalMapKey, string) => void,
    notifyPropertyChange: (string) => void,
    addError: <T: string | ErrLike<*>>(string, T) => T,
    _setProperty: <T>(ValidationResult, NewProperty<T>) => (T | ErrLike<T>),
    _validateAndSet: <T>(string, T) => (Promise<T> | Promise<ErrLike<T>> | T | ErrLike<T>),
    _setIsValidating: (string, boolean) => void,
    _validate: (string, mixed, mixed) => (ValidationResult | Promise<ValidationResult>),
    trigger: (string, string | void) => void,
    isValidating: (string | void) => boolean,
    cast: (Array<string>) => ChangesetDef,
    willDestroy: () => void,
    setUnknownProperty: <T>(string, T) => (T | ErrLike<T> | Promise<T> | Promise<ErrLike<T>>),
    prepare: (({ [string]: mixed }) => ({ [string]: mixed })) => ChangesetDef,
    execute: () => ChangesetDef,
    _notifyVirtualProperties: (?Array<string>) => void,
    _rollbackKeys: () => Array<string>,
    rollback: () => ChangesetDef,
    rollbackInvalid: (string | void) => ChangesetDef,
    rollbackProperty: () => ChangesetDef,
    save: (Object) => Promise<ChangesetDef | mixed>,
    merge: (ChangesetDef) => ChangesetDef,
    validate: (string | void) => (Promise<null> | Promise<mixed | ErrLike<mixed>> | Promise<Array<mixed | ErrLike<mixed>>>),
    pushErrors: (string, ...string) => ErrLike<mixed>,
    snapshot: () => Snapshot,
    restore: (Snapshot) => ChangesetDef,
  |};
  */

  /**
   * Creates new changesets.
   *
   * @uses Ember.Evented
   */
  function changeset(obj /*: Object                      */, validateFn /*: ValidatorFunc               */ = defaultValidatorFn, validationMap /*: { [string]: ValidatorFunc } */ = {}, options /*: Config                      */ = {}) /*: Class<ChangesetDef> */{
    (false && !((0, _utils.isPresent)(obj)) && (0, _debug.assert)('Underlying object for changeset is missing', (0, _utils.isPresent)(obj)));
    return _object.default.extend(_evented.default, {
      /**
       * Internal descriptor for changeset identification.
       */
      __changeset__: _isChangeset.CHANGESET,
      changes: (0, _objectToArray.default)(CHANGES, (c /*: Change */) => c.value, false),
      errors: (0, _objectToArray.default)(ERRORS, (e /*: Err */) => ({
        value: e.value,
        validation: e.validation
      }), true),
      change: (0, _inflate.default)(CHANGES, c => c.value),
      error: (0, _inflate.default)(ERRORS, e => ({
        value: e.value,
        validation: e.validation
      })),
      data: (0, _computed.readOnly)(CONTENT),
      isValid: (0, _isEmptyObject.default)(ERRORS),
      isPristine: (0, _isEmptyObject.default)(CHANGES),
      isInvalid: (0, _computed.not)('isValid').readOnly(),
      isDirty: (0, _computed.not)('isPristine').readOnly(),
      _bareChanges: (0, _transform.default)(CHANGES, c => c.value),
      /*::
      _super() {},
      notifyPropertyChange() {},
      _content: {},
      _changes: {},
      _errors: {},
      _relayCache: {},
      _validator: defaultValidatorFn,
      _options: defaultOptions,
      _runningValidations: {},
      trigger() {},
      */

      init() {
        let c /*: ChangesetDef */ = this;
        c._super(...arguments);
        c[CONTENT] = obj;
        c[CHANGES] = {};
        c[ERRORS] = {};
        c[RELAY_CACHE] = {};
        c[VALIDATOR] = validateFn;
        c[OPTIONS] = (0, _assign.default)(defaultOptions, options);
        c[RUNNING_VALIDATIONS] = {};
      },
      /**
       * Proxies `get` to the underlying content or changed value, if present.
       */
      unknownProperty(key /*: string */) /*: RelayDef | mixed */{
        return this /*: ChangesetDef */._valueFor(key);
      },
      /**
       * Stores change on the changeset.
       */
      setUnknownProperty /*:: <T> */(key /*: string */, value /*: T      */) /*: T | ErrLike<T> | Promise<T> | Promise<ErrLike<T>> */{
        let config /*: Config       */ = (0, _object.get)(this, OPTIONS);
        let skipValidate /*: boolean      */ = (0, _object.get)(config, 'skipValidate');
        let c /*: ChangesetDef */ = this;
        if (skipValidate) {
          let content = (0, _object.get)(this, CONTENT);
          let oldValue = (0, _object.get)(content, key);
          return c._setProperty(true, {
            key,
            value,
            oldValue
          });
        }
        return c._validateAndSet(key, value);
      },
      /**
       * String representation for the changeset.
       */
      toString() /*: string */{
        let normalisedContent /*: Object */ = (0, _assign.default)((0, _object.get)(this, CONTENT), {});
        return `changeset:${normalisedContent.toString()}`;
      },
      /**
       * Teardown relays from cache.
       */
      willDestroy() /*: void */{
        let relayCache /*: RelayCache */ = (0, _object.get)(this, RELAY_CACHE);
        for (let key in relayCache) relayCache[key].destroy();
      },
      /**
       * Provides a function to run before emitting changes to the model. The
       * callback function must return a hash in the same shape:
       *
       * ```
       * changeset
       *   .prepare((changes) => {
       *     let modified = {};
       *
       *     for (let key in changes) {
       *       modified[underscore(key)] = changes[key];
       *     }
       *
       *    return modified; // { first_name: "Jim", last_name: "Bob" }
       *  })
       *  .execute(); // execute the changes
       * ```
       */
      prepare(prepareChangesFn /*: ({ [string]: mixed }) => ({ [string]: mixed }) */) /*: ChangesetDef */{
        let changes /*: { [string]: mixed } */ = (0, _object.get)(this, '_bareChanges');
        let preparedChanges = prepareChangesFn(changes);
        (false && !((0, _isObject.default)(preparedChanges)) && (0, _debug.assert)('Callback to `changeset.prepare` must return an object', (0, _isObject.default)(preparedChanges)));
        (0, _validateNestedObj.default)('preparedChanges', preparedChanges);
        let newChanges /*: Changes */ = keys(preparedChanges).reduce((newObj, key) => {
          newObj[key] = new _change.default(preparedChanges[key]);
          return newObj;
        }, {});
        (0, _object.set)(this, CHANGES, newChanges);
        return this;
      },
      /**
       * Executes the changeset if in a valid state.
       */
      execute() /*: ChangesetDef */{
        if ((0, _object.get)(this, 'isValid') && (0, _object.get)(this, 'isDirty')) {
          let content /*: Object  */ = (0, _object.get)(this, CONTENT);
          let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
          keys(changes).forEach(key => (0, _emberDeepSet.default)(content, key, changes[key].value));
        }
        return this;
      },
      /**
       * Executes the changeset and saves the underlying content.
       *
       * @param {Object} options optional object to pass to content save method
       */
      save(options /*: Object */) /*: Promise<ChangesetDef | mixed> */{
        let content /*: Object */ = (0, _object.get)(this, CONTENT);
        let savePromise /*: mixed | Promise<ChangesetDef | mixed> */ = (0, _rsvp.resolve)(this);
        this /*: ChangesetDef */.execute();
        if (typeof content.save === 'function') {
          let result /*: mixed | Promise<mixed> */ = content.save(options);
          savePromise = result;
        }
        return (0, _rsvp.resolve)(savePromise).then(result => {
          this /*: ChangesetDef */.rollback();
          return result;
        });
      },
      /**
       * Merges 2 valid changesets and returns a new changeset. Both changesets
       * must point to the same underlying object. The changeset target is the
       * origin. For example:
       *
       * ```
       * let changesetA = new Changeset(user, validatorFn);
       * let changesetB = new Changeset(user, validatorFn);
       * changesetA.set('firstName', 'Jim');
       * changesetB.set('firstName', 'Jimmy');
       * changesetB.set('lastName', 'Fallon');
       * let changesetC = changesetA.merge(changesetB);
       * changesetC.execute();
       * user.get('firstName'); // "Jimmy"
       * user.get('lastName'); // "Fallon"
       * ```
       */
      merge(changeset /*: ChangesetDef */) /*: ChangesetDef */{
        let content /*: Object */ = (0, _object.get)(this, CONTENT);
        (false && !((0, _isChangeset.default)(changeset)) && (0, _debug.assert)('Cannot merge with a non-changeset', (0, _isChangeset.default)(changeset)));
        (false && !((0, _object.get)(changeset, CONTENT) === content) && (0, _debug.assert)('Cannot merge with a changeset of different content', (0, _object.get)(changeset, CONTENT) === content));
        if ((0, _object.get)(this, 'isPristine') && (0, _object.get)(changeset, 'isPristine')) {
          return this;
        }

        // Note: we do not need to merge the RelayCache because the new
        // changeset will create its own relays if necessary.

        let c1 /*: Changes    */ = (0, _object.get)(this, CHANGES);
        let c2 /*: Changes    */ = (0, _object.get)(changeset, CHANGES);
        let e1 /*: Errors     */ = (0, _object.get)(this, ERRORS);
        let e2 /*: Errors     */ = (0, _object.get)(changeset, ERRORS);
        let newChangeset /*: ChangesetDef */ = new Changeset(content, (0, _object.get)(this, VALIDATOR));
        let newErrors /*: Errors  */ = (0, _objectWithout.default)(keys(c2), e1);
        let newChanges /*: Changes */ = (0, _objectWithout.default)(keys(e2), c1);
        let mergedErrors /*: Errors  */ = (0, _mergeNested.default)(newErrors, e2);
        let mergedChanges /*: Changes */ = (0, _mergeNested.default)(newChanges, c2);
        newChangeset[ERRORS] = mergedErrors;
        newChangeset[CHANGES] = mergedChanges;
        newChangeset._notifyVirtualProperties();
        return newChangeset;
      },
      /**
       * Returns the changeset to its pristine state, and discards changes and
       * errors.
       */
      rollback() /*: ChangesetDef */{
        // Notify keys contained in relays.
        let relayCache /*: RelayCache */ = (0, _object.get)(this, RELAY_CACHE);
        for (let key in relayCache) relayCache[key].rollback();

        // Get keys before reset.
        let c /*: ChangesetDef     */ = this;
        let keys = c._rollbackKeys();

        // Reset.
        (0, _object.set)(this, RELAY_CACHE, {});
        (0, _object.set)(this, CHANGES, {});
        (0, _object.set)(this, ERRORS, {});
        c._notifyVirtualProperties(keys);
        c.trigger(AFTER_ROLLBACK_EVENT);
        return this;
      },
      /**
       * Discards any errors, keeping only valid changes.
       *
       * @public
       * @chainable
       * @param {String} key optional key to rollback invalid values
       * @return {Changeset}
       */
      rollbackInvalid(key /*: string | void */) /*: ChangesetDef */{
        let errorKeys = keys((0, _object.get)(this, ERRORS));
        if (key) {
          this._notifyVirtualProperties([key]);
          this._deleteKey(ERRORS, key);
          if (errorKeys.indexOf(key) > -1) {
            this._deleteKey(CHANGES, key);
          }
        } else {
          this._notifyVirtualProperties();
          (0, _object.set)(this, ERRORS, {});

          // if on CHANGES hash, rollback those as well
          errorKeys.forEach(errKey => {
            this._deleteKey(CHANGES, errKey);
          });
        }
        return this;
      },
      /**
       * Discards changes/errors for the specified properly only.
       *
       * @public
       * @chainable
       * @param {String} key key to delete off of changes and errors
       * @return {Changeset}
       */
      rollbackProperty(key) /*: ChangesetDef */{
        this._deleteKey(CHANGES, key);
        this._deleteKey(ERRORS, key);
        return this;
      },
      /**
       * Validates the changeset immediately against the validationMap passed in.
       * If no key is passed into this method, it will validate all fields on the
       * validationMap and set errors accordingly. Will throw an error if no
       * validationMap is present.
       */
      validate(key /*: string | void */) /*: Promise<null> | Promise<mixed | ErrLike<mixed>> | Promise<Array<mixed | ErrLike<mixed>>> */{
        if (keys(validationMap).length === 0) {
          return (0, _rsvp.resolve)(null);
        }
        let c /*: ChangesetDef */ = this;
        if ((0, _utils.isNone)(key)) {
          let maybePromise = keys(validationMap).map(validationKey => {
            const isPlain = true;
            return c._validateAndSet(validationKey, c._valueFor(validationKey, isPlain));
          });
          return (0, _rsvp.all)(maybePromise);
        }
        let k /*: string */ = key /*: any */;
        const isPlain = true;
        return (0, _rsvp.resolve)(c._validateAndSet(k, c._valueFor(k, isPlain)));
      },
      /**
       * Manually add an error to the changeset. If there is an existing
       * error or change for `key`, it will be overwritten.
       */
      addError /*:: <T: string | ErrLike<*>> */(key /*: string */, error /*: T      */) /*: T */{
        // Construct new `Err` instance.
        let newError /*: Err */;
        if ((0, _isObject.default)(error)) {
          let errorLike /*: ErrLike<*> */ = error /*: any */;
          (false && !(errorLike.hasOwnProperty('value')) && (0, _debug.assert)('Error must have value.', errorLike.hasOwnProperty('value')));
          (false && !(errorLike.hasOwnProperty('validation')) && (0, _debug.assert)('Error must have validation.', errorLike.hasOwnProperty('validation')));
          newError = new _err.default(errorLike.value, errorLike.validation);
        } else {
          let validation /*: ValidationErr */ = error /*: any */;
          newError = new _err.default((0, _object.get)(this, key), validation);
        }

        // Remove `key` from changes map.
        let c = this /*: ChangesetDef */;

        // Add `key` to errors map.
        let errors /*: Errors */ = (0, _object.get)(this, ERRORS);
        (0, _setNestedProperty.default)(errors, key, newError);
        c.notifyPropertyChange(ERRORS);

        // Notify that `key` has changed.
        c.notifyPropertyChange(key);

        // Return passed-in `error`.
        return error;
      },
      /**
       * Manually push multiple errors to the changeset as an array.
       */
      pushErrors(key /*: string        */, ...newErrors /*: Array<string> */) /*: ErrLike<mixed> */{
        let errors /*: Errors */ = (0, _object.get)(this, ERRORS);
        let existingError /*: Err */ = errors[key] || new _err.default(null, []);
        let validation /*: ValidationErr */ = existingError.validation;
        let value /*: mixed */ = (0, _object.get)(this, key);
        if (!(0, _array.isArray)(validation) && (0, _utils.isPresent)(validation)) {
          let v /*: string */ = existingError.validation /*: any */;
          existingError.validation = [v];
        }
        let v /*: Array<string> */ = existingError.validation /*: any */;
        validation = [...v, ...newErrors];
        let c = this /*: ChangesetDef */;
        c.notifyPropertyChange(ERRORS);
        c.notifyPropertyChange(key);
        errors[key] = new _err.default(value, validation);
        return {
          value,
          validation
        };
      },
      /**
       * Creates a snapshot of the changeset's errors and changes.
       */
      snapshot() /*: Snapshot */{
        let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
        let errors /*: Errors  */ = (0, _object.get)(this, ERRORS);
        return {
          changes: keys(changes).reduce((newObj, key) => {
            newObj[key] = changes[key].value;
            return newObj;
          }, {}),
          errors: keys(errors).reduce((newObj, key) => {
            let e = errors[key];
            newObj[key] = {
              value: e.value,
              validation: e.validation
            };
            return newObj;
          }, {})
        };
      },
      /**
       * Restores a snapshot of changes and errors. This overrides existing
       * changes and errors.
       */
      restore({
        changes,
        errors
      } /*: Snapshot */) /*: ChangesetDef */{
        (0, _validateNestedObj.default)('snapshot.changes', changes);
        (0, _validateNestedObj.default)('snapshot.errors', errors);
        let newChanges /*: Changes */ = keys(changes).reduce((newObj, key) => {
          newObj[key] = new _change.default(changes[key]);
          return newObj;
        }, {});
        let newErrors /*: Errors */ = keys(errors).reduce((newObj, key) => {
          let e /*: ErrLike<*> */ = errors[key];
          newObj[key] = new _err.default(e.value, e.validation);
          return newObj;
        }, {});
        (0, _object.set)(this, CHANGES, newChanges);
        (0, _object.set)(this, ERRORS, newErrors);
        this /*: ChangesetDef */._notifyVirtualProperties();
        return this;
      },
      /**
       * Unlike `Ecto.Changeset.cast`, `cast` will take allowed keys and
       * remove unwanted keys off of the changeset. For example, this method
       * can be used to only allow specified changes through prior to saving.
       */
      cast(allowed /*: Array<string> */ = []) /*: ChangesetDef */{
        let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
        if ((0, _array.isArray)(allowed) && allowed.length === 0) {
          return this;
        }
        let changeKeys /*: Array<string> */ = keys(changes);
        let validKeys = (0, _array.A)(changeKeys).filter((key /*: string */) => (0, _includes.default)(allowed, key));
        let casted = (0, _take.default)(changes, validKeys);
        (0, _object.set)(this, CHANGES, casted);
        return this;
      },
      /**
       * Checks to see if async validator for a given key has not resolved.
       * If no key is provided it will check to see if any async validator is running.
       */
      isValidating(key /*: string | void */) /*: boolean */{
        let runningValidations /*: RunningValidations */ = (0, _object.get)(this, RUNNING_VALIDATIONS);
        let ks /*: Array<string> */ = (0, _array.A)(keys(runningValidations));
        if (key) return (0, _includes.default)(ks, key);
        return !(0, _utils.isEmpty)(ks);
      },
      /**
       * For a given key and value, set error or change.
       */
      _validateAndSet /*:: <T> */(key /*: string */, value /*: T      */) /*: Promise<T> | Promise<ErrLike<T>> | T | ErrLike<T> */{
        let c /*: ChangesetDef     */ = this;
        let content /*: Object           */ = (0, _object.get)(this, CONTENT);
        let oldValue /*: mixed            */ = (0, _object.get)(content, key);
        let validation /*: ValidationResult | Promise<ValidationResult> */ = c._validate(key, value, oldValue);
        let v /*: ValidationResult */ = validation /*: any */;
        c.trigger(BEFORE_VALIDATION_EVENT, key);
        let result = c._setProperty(v, {
          key,
          value,
          oldValue
        });

        // TODO: Address case when Promise is rejected.
        if ((0, _isPromise.default)(validation)) {
          c._setIsValidating(key, true);
          let v /*: Promise<ValidationResult> */ = validation /*: any */;
          return v.then(resolvedValidation => {
            c._setIsValidating(key, false);
            c.trigger(AFTER_VALIDATION_EVENT, key);
            return c._setProperty(resolvedValidation, {
              key,
              value,
              oldValue
            });
          });
        }
        c.trigger(AFTER_VALIDATION_EVENT, key);
        return result;
      },
      /**
       * Validates a given key and value.
       */
      _validate(key /*: string */, newValue /*: mixed  */, oldValue /*: mixed  */) /*: ValidationResult | Promise<ValidationResult> */{
        let validator /*: ValidatorFunc */ = (0, _object.get)(this, VALIDATOR);
        let content /*: Object        */ = (0, _object.get)(this, CONTENT);
        if (typeof validator === 'function') {
          let isValid = validator({
            key,
            newValue,
            oldValue,
            changes: (0, _object.get)(this, 'change'),
            content
          });
          return (0, _utils.isPresent)(isValid) ? isValid : true;
        }
        return true;
      },
      /**
       * Sets property or error on the changeset.
       */
      _setProperty /*:: <T> */(validation /*: ValidationResult */, {
        key,
        value,
        oldValue
      } /*: NewProperty<T>   */) /*: T | ErrLike<T> */{
        (false && !(!(0, _isRelay.default)(value)) && (0, _debug.assert)('Value must not be a Relay. If you see this error, please open an issue on https://github.com/poteto/ember-changeset/issues.', !(0, _isRelay.default)(value)));
        let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
        let isValid /*: boolean */ = validation === true || (0, _array.isArray)(validation) && validation.length === 1 && validation /*: any */[0] === true;

        // Shorthand for `this`.
        let c /*: ChangesetDef */ = this;

        // Happy path: remove `key` from error map.
        c._deleteKey(ERRORS, key);

        // Happy path: update change map.
        if (!(0, _utils.isEqual)(oldValue, value)) {
          (0, _setNestedProperty.default)(changes, key, new _change.default(value));

          // ensure cache key is updated with new relay if value is object
          if ((0, _isObject.default)(value)) {
            let cache /*: RelayCache */ = (0, _object.get)(this, RELAY_CACHE);
            cache[key] = _relay.default.create({
              key,
              changeset: this,
              content: value
            });
          }
        } else if (key in changes) {
          c._deleteKey(CHANGES, key);
        }

        // Happy path: notify that `key` was added.
        c.notifyPropertyChange(CHANGES);
        c.notifyPropertyChange(key);

        // Error case.
        if (!isValid) {
          let v /*: ValidationErr */ = validation /*: any */;
          return c.addError(key, {
            value,
            validation: v
          });
        }

        // Return new value.
        return value;
      },
      /**
       * Increment or decrement the number of running validations for a
       * given key.
       */
      _setIsValidating(key /*: string  */, value /*: boolean */) /*: void */{
        let running /*: RunningValidations */ = (0, _object.get)(this, RUNNING_VALIDATIONS);
        let count /*: number             */ = (0, _object.get)(running, key) || 0;
        if (!value && count === 1) {
          delete running[key];
          return;
        }
        (0, _emberDeepSet.default)(running, key, value ? count + 1 : count - 1);
      },
      /**
       * Value for change or the original value.
       */
      _valueFor(key /*: string  */, plainValue /*: ?boolean */ = false) /*: RelayDef | mixed */{
        let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
        let errors /*: Errors  */ = (0, _object.get)(this, ERRORS);
        let content /*: Object  */ = (0, _object.get)(this, CONTENT);
        if (errors.hasOwnProperty(key)) {
          let e /*: Err */ = errors[key];
          return e.value;
        }
        let original /*: mixed */ = (0, _object.get)(content, key);
        if (original && (0, _isObject.default)(original) && !plainValue) {
          let c /*: ChangesetDef */ = this;
          let o /*: Object       */ = original /*: any */;
          return c._relayFor(key, o);
        }
        if (changes.hasOwnProperty(key)) {
          let c /*: Change */ = changes[key];
          return c.value;
        }

        // nested thus circulate through `value` and see if match
        if (key.indexOf('.') !== -1) {
          let [baseKey, ...keyParts] = key.split('.');
          if (changes.hasOwnProperty(baseKey)) {
            let {
              value
            } = changes[baseKey];
            // make sure to return value if not object
            if (!value) {
              return value;
            }
            let result = (0, _object.get)(value, keyParts.join('.'));
            if (result) {
              return result;
            }
          }
        }
        return original;
      },
      /**
       * Construct a Relay instance for an object.
       */
      _relayFor(key /*: string */, value /*: Object */) /*: RelayDef */{
        let cache /*: RelayCache */ = (0, _object.get)(this, RELAY_CACHE);
        if (!(key in cache)) {
          cache[key] = _relay.default.create({
            key,
            changeset: this,
            content: value
          });
        }
        return cache[key];
      },
      /**
       * Notifies virtual properties set on the changeset of a change.
       * You can specify which keys are notified by passing in an array.
       *
       * @private
       * @param {Array} keys
       * @return {Void}
       */
      _notifyVirtualProperties(keys /*: ?Array<string> */ = this /*: ChangesetDef */._rollbackKeys()) /*: void */{
        (keys || []).forEach(key => this /*: ChangesetDef */.notifyPropertyChange(key));
      },
      /**
       * Gets the changes and error keys.
       */
      _rollbackKeys() /*: Array<string> */{
        let changes /*: Changes */ = (0, _object.get)(this, CHANGES);
        let errors /*: Errors  */ = (0, _object.get)(this, ERRORS);
        return (0, _array.A)([...keys(changes), ...keys(errors)]).uniq();
      },
      /**
       * Deletes a key off an object and notifies observers.
       */
      _deleteKey(objName /*: string */, key /*: string */ = '') /*: void */{
        let obj /*: InternalMap */ = (0, _object.get)(this, objName);
        if (obj.hasOwnProperty(key)) {
          delete obj[key];
        }
        let c /*: ChangesetDef */ = this;
        c.notifyPropertyChange(`${objName}.${key}`);
        c.notifyPropertyChange(objName);
      },
      /**
       * Overrides `Ember.Object.get`.
       *
       * If the returned value is a Relay, return the Relay's underlying
       * content instead.
       *
       * Otherwise, this method is equivalent to `Ember.Object.get`.
       */
      get(keyName /*: string */) /*: mixed */{
        let result = this._super(keyName);
        if ((0, _isRelay.default)(result)) {
          return (0, _object.get)(result, 'content');
        }
        return result;
      }
    } /*: ChangesetDef */);
  }
  class Changeset {
    /**
     * Changeset factory
     *
     * @class Changeset
     * @constructor
     */
    constructor(...args /*: Array<any> */) {
      return changeset(...args).create();
    }
  }
  _exports.default = Changeset;
});