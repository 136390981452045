define("@heroku/ember-backboard/services/analytics", ["exports", "@ember/application", "@ember/utils", "@ember/service"], function (_exports, _application, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Analytics extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "accountId", null);
    }
    get backboardUrl() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config && config.backboardUrl;
    }
    get source() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config && config.backboardSourceName;
    }

    /**
     * trackUser - Set the user account id to associate with tracking events
     *             This should be called once as soon as a user is authenticated
     *             and all subsequent events will have that user associated with them
     *
     * @param  {type} account The account to associate with the events
     */
    trackUser(account) {
      this.accountId = account.id;
    }

    /**
     * logEvent - send an event to backboard
     *
     * @param  {type} noun          Noun describing event name
     * @param  {type} action        Verb describing the event
     * @param  {type} properties={} Object to send along with event
     */
    logEvent(noun, action, properties = {}) {
      const userId = this.accountId;
      if ((0, _utils.isEmpty)(userId)) {
        // this scenario, at a minimum, happens during the OAuth redirect login
        // as the user's session has not yet been established upon transitioning
        // into the first route
        return;
      }
      const source = this.source;
      const event = `${noun} ${action}`;

      // attempt to retrieve and pass along the current path
      const routerService = (0, _application.getOwner)(this).lookup('service:router');
      properties.route = routerService ? routerService.currentPath : window.location.pathname;
      const url = this._sanitize(window.location.href); // this includes proto and domain
      const path = window.location.pathname;
      const search = this._sanitize(window.location.search); // these are the query params
      const title = document.title; // page title
      const referrer = document.referrer;
      const page = {
        url,
        path,
        search,
        title,
        referrer
      };
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        properties.effectiveNetworkType = connection.effectiveType;
      }
      this._transmit({
        source,
        event,
        userId,
        properties,
        page
      });
    }
    _transmit(payload) {
      const backboardUrl = this.backboardUrl;

      // abort this request when backboard is unset
      if (!backboardUrl) {
        return;
      }
      const data = b64EncodeUnicode(JSON.stringify(payload));
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${backboardUrl}/hamurai?data=${data}`);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send();
    }
    _sanitize(str) {
      return str.replace(/code=.{36}/, 'code=[REDACTED]');
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  // tl;dr of MDN article:
  // JavaScript strings are UTF-16 by default.
  // UTF8 and UTF16 do not share all the same escape characters.
  // an example in dashboard specifically is that the document title has the `·`
  // (middle dot symbol https://unicode-table.com/en/00B7/).
  // Elixir strings are UTF-8, so we need to first escape the string as UTF8 before base64 encoding it
  // so the analytics service can do something with it.
  _exports.default = Analytics;
  function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  }
});