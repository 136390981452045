define("ember-data-github/adapters/github-user", ["exports", "ember-data-github/adapters/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      const isInteger = /^\d+$/;
      let builtURL = this._super(id, modelName, snapshot);
      if (id === '#') {
        builtURL = builtURL.replace('users/%23', 'user');
      } else if (isInteger.test(id)) {
        builtURL = builtURL.replace('/users/', '/user/');
      }
      return builtURL;
    }
  });
});