define("ember-element-helper/helpers/element", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function () {
    // This helper (`element`, as opposed to `-element`) mostly exists to satisfy
    // things like `owner.hasRegistration('helper:element')`. The AST transform
    // replaces all usages of `(element ...)` into `(component (-element ...))`
    // so if this helper is invoked directly, something is wrong.
    (false && !(false) && (0, _debug.assert)('The `element` helper polyfill encountered an unexpected error. ' + 'Please report the issue at http://github.com/tildeio/ember-element-helper ' + 'with the usage and conditions that caused this error.'));
    return null;
  });
});