define("ember-data-github/serializers/github", ["exports", "@ember/array", "@ember/object", "@ember/utils", "ember-inflector", "ember-data", "@ember/string"], function (_exports, _array, _object, _utils, _emberInflector, _emberData, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.RESTSerializer.extend({
    keyForAttribute(attr) {
      return (0, _string.decamelize)(attr);
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.recordId = id;
      let wrappedPayload = {};
      let fieldName = primaryModelClass.modelName;
      if ((0, _array.isArray)(payload)) {
        fieldName = (0, _emberInflector.pluralize)(fieldName);
      }
      wrappedPayload[fieldName] = payload;
      return this._super(store, primaryModelClass, wrappedPayload, id, requestType);
    },
    // Add metadata to the response for use with pagination. Formatted like:
    //   {
    //     first: { page: 1, per_page: 5 }
    //     next:  { page: 3, per_page: 5 },
    //     prev:  { page: 1, per_page: 5 },
    //     last:  { page: 3, per_page: 5 }
    //   }
    //
    extractMeta(store, modelClass, payload) {
      const links = (0, _object.get)(payload, `${(0, _emberInflector.pluralize)(modelClass.modelName)}.links`);
      if ((0, _utils.isNone)(links)) {
        return;
      }
      return Object.keys(links).reduce((meta, name) => {
        const link = links[name];
        const qs = link.split('?').pop();
        meta[name] = qs.split('&').reduce((memo, str) => {
          const [key, value] = str.split('=');
          memo[key] = parseInt(value, 10);
          return memo;
        }, {});
        return meta;
      }, {});
    }
  });
});