define("ember-data-github/serializers/github-pull", ["exports", "ember-data-github/serializers/github", "ember-data"], function (_exports, _github, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;
  var _default = _exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      user: {
        embedded: 'always'
      }
    },
    normalize(modelClass, resourceHash, prop) {
      resourceHash.user_avatar_url = resourceHash.user.avatar_url;
      resourceHash.user_login = resourceHash.user.login;
      return this._super(modelClass, resourceHash, prop);
    }
  });
});