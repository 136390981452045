define("ember-data-github/models/github-organization", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "@ember/application/deprecations", "@ember/object"], function (_exports, _model, _attr, _relationships, _deprecations, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    login: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    avatarUrl: (0, _attr.default)('string'),
    members: (0, _relationships.hasMany)('github-member'),
    repositories: (0, _relationships.hasMany)('github-repository'),
    githubUsers: (0, _object.computed)('members.[]', function () {
      (false && !(false) && (0, _deprecations.deprecate)('The githubUsers property on the github-organization model has been deprecated.  Please use the members property.', false, {
        id: 'ember-data-github.deprecated-model-props',
        until: '1.0.0'
      }));
      return this.get('members');
    }),
    githubRepositories: (0, _object.computed)('repositories.[]', function () {
      (false && !(false) && (0, _deprecations.deprecate)('The githubRepositories property on the github-organization model has been deprecated.  Please use the repositories property.', false, {
        id: 'ember-data-github.deprecated-model-props',
        until: '1.0.0'
      }));
      return this.get('repositories');
    })
  });
});