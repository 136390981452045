define("@heroku/ember-hk-components/components/hk-slide-panel/content", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/content", "@ember/object", "body-scroll-lock"], function (_exports, _component, _content, _object, _bodyScrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    bodyScrollLock: _bodyScrollLock.default,
    layout: _content.default,
    'data-test-target': 'slide-panel-content',
    classNames: ['hk-slide-panel-content', 'relative', 'overflow-y-auto', 'flex', 'flex-column', 'z-1'],
    paddingClass: (0, _object.computed)('overridePadding', function () {
      return this.overridePadding ? 'pa0' : 'pa4';
    }),
    didInsertElement() {
      this._super(...arguments);
      this.bodyScrollLock.disableBodyScroll(this.element);
    },
    willDestroyElement() {
      this.bodyScrollLock.enableBodyScroll(this.element);
      this._super(...arguments);
    }
  });
});