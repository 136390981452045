define("ember-data-github/models/github-branch", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    commit: (0, _attr.default)(),
    protected: (0, _attr.default)('boolean')
  });
});