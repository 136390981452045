define("ember-cli-flash/utils/computed", ["exports", "@ember/utils", "@ember/object", "@ember/object/internals", "@ember/array"], function (_exports, _utils, _object, _internals, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.guidFor = guidFor;
  function add(...dependentKeys) {
    const computedFunc = (0, _object.computed)({
      get() {
        const values = dependentKeys.map(dependentKey => {
          const value = (0, _object.get)(this, dependentKey);
          if ((0, _utils.typeOf)(value) !== 'number') {
            return;
          }
          return value;
        });
        return (0, _array.A)(values).compact().reduce((prev, curr) => {
          return prev + curr;
        });
      }
    });
    return computedFunc.property.apply(computedFunc, dependentKeys);
  }
  function guidFor(dependentKey) {
    return (0, _object.computed)(dependentKey, {
      get() {
        const value = (0, _object.get)(this, dependentKey);
        return (0, _internals.guidFor)(value.toString());
      }
    });
  }
});