define("ember-data-github/models/github-member", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    login: (0, _attr.default)('string'),
    avatarUrl: (0, _attr.default)('string'),
    gravatarId: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    siteAdmin: (0, _attr.default)('boolean'),
    url: (0, _attr.default)('string')
  });
});