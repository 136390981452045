define("ember-changeset/-private/err", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @flow

  /*::
  import type { ValidationErr } from 'ember-changeset/types/validation-result';
  
  export type ErrLike<T> = {
    value: T,
    validation: ValidationErr,
  };
  */

  class Err {
    /*::
    value: mixed;
    validation: ValidationErr;
    */

    constructor(value /*: mixed */, validation /*: ValidationErr */) {
      this.value = value;
      this.validation = validation;
    }
  }
  _exports.default = Err;
});