define("ember-changeset-validations/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset", "ember-changeset-validations", "ember-changeset/utils/is-object", "ember-changeset/utils/is-promise"], function (_exports, _helper, _emberChangeset, _emberChangesetValidations, _isObject, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  function changeset([model, validationMap], options = {}) {
    if ((0, _isObject.default)(validationMap)) {
      if ((0, _isPromise.default)(model)) {
        return model.then(resolved => new _emberChangeset.default(resolved, (0, _emberChangesetValidations.default)(validationMap), validationMap, options));
      }
      return new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validationMap), validationMap, options);
    }
    if ((0, _isPromise.default)(model)) {
      return model.then(resolved => new _emberChangeset.default(resolved, validationMap, {}, options));
    }
    return new _emberChangeset.default(model, validationMap, {}, options);
  }
  var _default = _exports.default = (0, _helper.helper)(changeset);
});