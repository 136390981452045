define("ember-data-github/initializers/github-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    const inflector = _emberInflector.default.inflector;
    inflector.uncountable('githubRepositoryContents');
  }
  var _default = _exports.default = {
    name: 'github-inflector-rules',
    initialize
  };
});