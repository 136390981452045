define("ember-changeset/utils/computed/object-to-array", ["exports", "@ember/object", "@ember/utils", "@ember/polyfills"], function (_exports, _object, _utils, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = objectToArray;
  // @flow

  /*::
  import type Change from 'ember-changeset/-private/change';
  import type Err from 'ember-changeset/-private/err';
  */

  const {
    keys
  } = Object;

  /**
   * Compute the array form of an object.
   *
   * Each value of the object is transformed by a passed-in `transform`
   * function.
   *
   * Returns a list of objects, where each object has the form
   * `{ key, value }`. If `flattenObjects` is true and the result of
   * `transform` is an Object, the resulting object has the form
   * `{ key, ...transformResult }`.
   */
  function objectToArray /*:: <T> */(objKey /*: string             */, transform /*: (T) => (mixed) */ = a => a, flattenObjects /*: boolean            */ = false) /*: Array<{ key: string }> */{
    return (0, _object.computed)(objKey, function () {
      let obj = (0, _object.get)(this, objKey);
      return keys(obj).map(key => {
        let value = transform(obj[key]);
        if (flattenObjects && (0, _utils.typeOf)(value) === 'object') {
          return (0, _polyfills.assign)({
            key
          }, value);
        }
        return {
          key,
          value
        };
      });
    }).readOnly();
  }
});