define("ember-changeset/helpers/changeset", ["exports", "ember-changeset", "ember-changeset/utils/is-changeset", "ember-changeset/utils/is-promise", "@ember/component/helper"], function (_exports, _emberChangeset, _isChangeset, _isPromise, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;
  // @flow

  /*::
  import type { ValidatorFunc } from 'ember-changeset/types/validator-func';
  import type { Config } from 'ember-changeset/types/config';
  */

  function changeset([obj, validations] /*: [Object, ValidatorFunc] */, options /*: Config */ = {}) {
    if ((0, _isChangeset.default)(obj)) {
      return obj;
    }
    if ((0, _isPromise.default)(obj)) {
      return obj.then(resolved => new _emberChangeset.default(resolved, validations, {}, options));
    }
    return new _emberChangeset.default(obj, validations, {}, options);
  }
  var _default = _exports.default = (0, _helper.helper)(changeset);
});