define("@heroku/ember-hk-components/components/hk-field-validations", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@heroku/ember-hk-components/templates/components/hk-field-validations"], function (_exports, _component, _object, _computed, _runloop, _hkFieldValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _hkFieldValidations.default,
    classNameBindings: ['isInvalid:has-validation-errors'],
    debounceValidation: 1000,
    isValidating: false,
    isNotValidating: (0, _computed.not)('isValidating'),
    hasValidated: false,
    hasValidationErrors: (0, _computed.notEmpty)('validationErrors'),
    doesNotHaveValidationErrors: (0, _computed.not)('hasValidationErrors'),
    isValid: (0, _computed.and)('isNotValidating', 'hasValidated', 'doesNotHaveValidationErrors'),
    isInvalid: (0, _computed.and)('isNotValidating', 'hasValidated', 'hasValidationErrors'),
    changesetErrors: (0, _computed.alias)('changeset.error'),
    validationErrors: (0, _object.computed)('changesetErrors', function () {
      const changesetErrors = this.get('changesetErrors');
      const property = this.get('property');
      const errors = (0, _object.get)(changesetErrors, property);

      // filter out validations that are promises...
      // ember changeset seems to put the validation promise as the value for `validation`
      // before it resolves or rejects.
      if (!errors || typeof errors.validation === 'object' && errors.validation !== null && typeof errors.validation.then === 'function') {
        return;
      }
      return errors;
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      this.cleanUpObservers();
      let changeset = this.get('changeset');
      let property = this.get('property');
      changeset.addObserver(property, this, 'valueDidChange');
      changeset.on('beforeValidation', this, 'beforeValidation');
      changeset.on('afterValidation', this, 'afterValidation');
      this._prev = {
        changeset,
        property
      };
    },
    willDestroyElement() {
      this.cleanUpObservers();
      this._super(...arguments);
    },
    beforeValidation(key) {
      if (key === this.property) {
        this.set('isValidating', true);
      }
    },
    afterValidation(key) {
      if (key === this.property) {
        this.set('hasValidated', true);
        this.set('isValidating', false);
      }
    },
    valueDidChange() {
      const newValue = this.get('changeset').get(this.get('property'));
      if (newValue === this._prevValue) {
        return;
      }
      this._prevValue = newValue;
      this.notifyPropertyChange('value');
      this.scheduleValidation();
    },
    cleanUpObservers() {
      if (!this._prev) {
        return;
      }
      let {
        changeset,
        property
      } = this._prev;
      changeset.removeObserver(property, this, 'valueDidChange');
      changeset.off('beforeValidation', this, 'beforeValidation');
      changeset.off('afterValidation', this, 'afterValidation');
      this._prev = null;
    },
    value: (0, _object.computed)('changeset', 'property', {
      get( /* key */
      ) {
        return this.get('changeset').get(this.get('property'));
      },
      set(key, value) {
        // The `valueDidChange` observer is responsible for invoking
        // `scheduleValidation` in reaction to this change.
        this.get('changeset').set(this.get('property'), value);
        return value;
      }
    }),
    scheduleValidation() {
      this.set('isValidating', true);
      (0, _runloop.debounce)(this, 'validateProperty', this.get('debounceValidation'));
    },
    validateProperty() {
      if (!this.get('isDestroyed')) {
        const changeset = this.get('changeset');
        const property = this.get('property');
        return changeset.validate(property);
      }
    }
  });
});