define("hk-components/components/hk-modal", ["exports", "@ember/object/computed", "@ember/object", "@ember/runloop", "@ember/service", "ember-modal-dialog/components/modal-dialog", "hk-components/templates/components/hk-modal", "hk-components/mixins/modal-behavior"], function (_exports, _computed, _object, _runloop, _service, _modalDialog, _hkModal, _modalBehavior) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ENTER_KEY = 13;
  var _default = _exports.default = _modalDialog.default.extend(_modalBehavior.default, {
    animatable: false,
    // opts us out of animating via liquid-fire
    layout: _hkModal.default,
    title: null,
    titleComponent: null,
    showHeader: true,
    hasTitle: (0, _computed.bool)('title'),
    clickOverlayToClose: true,
    trapFocus: false,
    isOpen: false,
    analytics: (0, _service.inject)(),
    hasCloseAction: (0, _computed.bool)('onClose'),
    hasPrimaryAction: (0, _computed.bool)('primaryAction'),
    hasSecondaryAction: (0, _computed.bool)('secondaryAction'),
    focusTrapIsActive: (0, _object.computed)('trapFocus', 'isOpen', function () {
      if (this.get('trapFocus')) {
        if (this.get('isOpen')) {
          return true;
        }
      }
      return false;
    }),
    cancelButtonText: 'Cancel',
    showFooter: (0, _computed.or)('hasPrimaryAction', 'hasSecondaryAction', 'hasCancelButton'),
    didInsertElement() {
      this._super(...arguments);
      this.set('isOpen', true);
      $('.modal-body :input:enabled:not([readonly]), [data-test-target="modal-primary-button"]').first().focus();
      this._initEnterListener();
    },
    _initEnterListener() {
      if (this.get('hasPrimaryAction')) {
        const closeOnEnterKey = ev => {
          const isNotTextArea = ev.target.nodeName !== 'TEXTAREA';
          if (ev.keyCode === ENTER_KEY && isNotTextArea) {
            _runloop.run.next(function () {
              // We need to click the submit button instead of invoking the action directly
              // so that the async button functionality works properly
              $('.modal-footer button[type="submit"]').click();
            });
          }
        };
        window.addEventListener('keydown', closeOnEnterKey);
        this.on('willDestroyElement', () => {
          window.removeEventListener('keydown', closeOnEnterKey);
        });
      }
    },
    actions: {
      close() {
        this.set('isOpen', false);
        this._closeModal();
      },
      primaryAction(...args) {
        if (this.primaryAction) {
          return this.primaryAction(...args);
        }
      },
      secondaryAction(...args) {
        if (this.secondaryAction) {
          return this.secondaryAction(...args);
        }
      },
      toggleModalSize() {
        this.toggleProperty('isExpanded');
        if (this.get('isExpanded')) {
          this.get('analytics').logEvent('Build Modal', 'Expanded');
        }
      }
    }
  });
});