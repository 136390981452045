define("ember-changeset/utils/computed/is-empty-object", ["exports", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmptyObject;
  // @flow

  const {
    keys
  } = Object;
  function isEmptyObject(dependentKey /*: string */) /*: boolean */{
    (false && !((0, _utils.isPresent)(dependentKey)) && (0, _debug.assert)('`dependentKey` must be defined', (0, _utils.isPresent)(dependentKey)));
    return (0, _object.computed)(dependentKey, function () {
      return keys((0, _object.get)(this, dependentKey)).length === 0;
    }).readOnly();
  }
});