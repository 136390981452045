define("ember-changeset-validations/utils/messages", ["exports", "ember", "ember-validators/messages"], function (_exports, _ember, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    String: {
      dasherize,
      capitalize
    }
  } = _ember.default;
  const assign = _ember.default.assign || _ember.default.merge;
  const Messages = assign({}, _messages.default);
  var _default = _exports.default = assign(Messages, {
    // Blank and present are flipped in ember-validators. Need to flip them back here
    blank: _messages.default.present,
    present: _messages.default.blank,
    getDescriptionFor(key = '') {
      return capitalize(dasherize(key).split(/[_-]/g).join(' '));
    }
  });
});