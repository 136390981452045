define("ember-data-github/adapters/github-organization", ["exports", "ember-data-github/adapters/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _github.default.extend({
    buildURL(type, id, snapshot) {
      let builtURL = this._super(type, id, snapshot);
      builtURL = builtURL.replace('organizations', 'orgs');
      return builtURL;
    }
  });
});