define("ember-changeset/utils/is-promise", ["exports", "@ember/utils", "ember-changeset/utils/is-object"], function (_exports, _utils, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;
  // @flow

  function isPromiseLike(obj /*: mixed */) /*: boolean */{
    return !!obj && !!obj.then && !!obj.catch && !!obj.finally && (0, _utils.typeOf)(obj.then) === 'function' && (0, _utils.typeOf)(obj.catch) === 'function' && (0, _utils.typeOf)(obj.finally) === 'function';
  }
  function isPromise(obj /*: mixed */) /*: boolean */{
    return (0, _isObject.default)(obj) && isPromiseLike(obj);
  }
});