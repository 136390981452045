define("ember-inflector/index", ["exports", "ember", "ember-inflector/lib/system", "@ember/application/deprecations", "ember-inflector/lib/ext/string"], function (_exports, _ember, _system, _deprecations, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "defaultRules", {
    enumerable: true,
    get: function () {
      return _system.defaultRules;
    }
  });
  Object.defineProperty(_exports, "pluralize", {
    enumerable: true,
    get: function () {
      return _system.pluralize;
    }
  });
  Object.defineProperty(_exports, "singularize", {
    enumerable: true,
    get: function () {
      return _system.singularize;
    }
  });
  _system.Inflector.defaultRules = _system.defaultRules;
  Object.defineProperty(_ember.default, 'Inflector', {
    get() {
      (false && !(false) && (0, _deprecations.deprecate)(`Ember.Inflector is deprecated. Please explicitly: import Inflector from 'ember-inflector';`, false, {
        id: 'ember-inflector.globals',
        until: '3.0.0'
      }));
      return _system.Inflector;
    }
  }, {
    configurable: true
  });
  Object.defineProperty(_ember.default.String, 'singularize', {
    get() {
      (false && !(false) && (0, _deprecations.deprecate)(`Ember.String.singularize() is deprecated. Please explicitly: import { singularize } from 'ember-inflector';`, false, {
        id: 'ember-inflector.globals',
        until: '3.0.0'
      }));
      return _system.singularize;
    }
  }, {
    configurable: true
  });
  Object.defineProperty(_ember.default.String, 'pluralize', {
    get() {
      (false && !(false) && (0, _deprecations.deprecate)(`Ember.String.pluralize() is deprecated. Please explicitly: import { pluralize } from 'ember-inflector';`, false, {
        id: 'ember-inflector.globals',
        until: '3.0.0'
      }));
      return _system.pluralize;
    }
  }, {
    configurable: true
  });
  var _default = _exports.default = _system.Inflector;
});