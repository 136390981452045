define("ember-svg-jar/inlined/dyno-eco", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873.985a2.374 2.374 0 012.255 0l4.745 2.586C14.57 3.951 15 4.654 15 5.414v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.43C1.43 12.05 1 11.346 1 10.586V5.414c0-.76.43-1.462 1.128-1.843L6.873.985z\" fill=\"#F0EEF4\" stroke=\"#BEB3D0\"/><path d=\"M8.018 6.162c.709 0 1.168.475 1.199 1.236h-2.45c.052-.746.547-1.236 1.251-1.236zm1.23 3.076c-.161.38-.573.594-1.173.594-.792 0-1.292-.531-1.313-1.386v-.078h3.946v-.464C10.708 6.079 9.707 5 8.013 5 6.303 5 5.25 6.157 5.25 8.029 5.25 9.895 6.282 11 8.034 11c1.407 0 2.398-.678 2.627-1.762H9.248z\" fill=\"#9D87BC\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});