define("ember-data-github/serializers/github-repository", ["exports", "ember-data-github/serializers/github", "ember-data"], function (_exports, _github, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;
  var _default = _exports.default = _github.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      owner: {
        embedded: 'always'
      }
    },
    normalize(modelClass, resourceHash, prop) {
      resourceHash.id = resourceHash.recordId || resourceHash.full_name;
      resourceHash.links = {
        defaultBranch: `${resourceHash.url}/branches/${resourceHash.default_branch}`,
        branches: `${resourceHash.url}/branches`,
        pulls: `${resourceHash.url}/pulls`,
        releases: `${resourceHash.url}/releases`
      };
      return this._super(modelClass, resourceHash, prop);
    }
  });
});