define("ember-data-github/models/github-repository-contents", ["exports", "ember-data/attr", "ember-data/model"], function (_exports, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    content: (0, _attr.default)('string'),
    downloadUrl: (0, _attr.default)('string'),
    encoding: (0, _attr.default)('string'),
    gitUrl: (0, _attr.default)('string'),
    htmlUrl: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    sha: (0, _attr.default)('string'),
    size: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),
    url: (0, _attr.default)('string')
  });
});