define("ember-sortable/templates/components/sortable-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WYHiIfV4",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\",\"model\"],[[50,\"ember-sortable@sortable-item\",0,null,[[\"direction\",\"registerItem\",\"deregisterItem\",\"setSelectedItem\",\"update\",\"prepare\",\"commit\",\"activateKeyDown\",\"deactivateKeyDown\"],[[30,0,[\"direction\"]],[28,[37,3],[[30,0],\"registerItem\"],null],[28,[37,3],[[30,0],\"deregisterItem\"],null],[28,[37,3],[[30,0],\"setSelectedItem\"],null],[28,[37,3],[[30,0],\"update\"],null],[28,[37,3],[[30,0],\"prepare\"],null],[28,[37,3],[[30,0],\"commit\"],null],[28,[37,3],[[30,0],\"activateKeyDown\"],null],[28,[37,3],[[30,0],\"deactivateKeyDown\"],null]]]],[30,0,[\"model\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "ember-sortable/templates/components/sortable-group.hbs",
    "isStrictMode": false
  });
});