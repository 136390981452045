define("ember-changeset/-private/relay", ["exports", "@ember/object/proxy", "@ember/object", "@ember/debug", "ember-changeset/utils/is-relay"], function (_exports, _proxy, _object, _debug, _isRelay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @flow
  /*::
  import type { ChangesetDef } from 'ember-changeset';
  */
  /*::
  export type RelayDef = {|
    changeset: ChangesetDef | null,
    key: string,
    content: Object | null,
    _changedKeys: Object,
    __relay__: typeof RELAY,
  
    _super: () => void,
    init: () => void,
    unknownProperty: (string) => mixed,
    setUnknownProperty: (string, mixed) => mixed,
    rollback: () => void,
    destroy: () => void,
    notifyPropertyChange: (string) => void,
    isEqual: (RelayDef) => boolean,
  |};
  */
  /**
   * A Relay delegates property accesses to its changeset. The property
   * may optionally be prefixed by `key`.
   *
   * This is done as a workaround for Ember's `unknownProperty` behavior.
   * Given the key "foo.bar.baz", Ember breaks the key into "foo", "bar",
   * and "baz", then feeds each string into `unknownProperty`. However, we
   * need the entire key "foo.bar.baz" in order to look up the change on
   * the changeset. A Relay will hold the key "foo.bar", and let us look
   * up "foo.bar.baz" on the changeset when "baz" is requested.
   */
  var _default = _exports.default = _proxy.default.extend({
    /*::
    _super() {},
    notifyPropertyChange() {},
    _changedKeys: {},
    */

    /**
     * Internal descriptor for relay identification.
     */
    __relay__: _isRelay.RELAY,
    changeset: null,
    key: '',
    content: null,
    init() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      r._changedKeys = {};
      (false && !(!!(0, _object.get)(this, 'changeset')) && (0, _debug.assert)('changeset must be present.', !!(0, _object.get)(this, 'changeset')));
      (false && !(!!(0, _object.get)(this, 'content')) && (0, _debug.assert)('content must be present.', !!(0, _object.get)(this, 'content')));
      (false && !(!!(0, _object.get)(this, 'key')) && (0, _debug.assert)('key must be present.', !!(0, _object.get)(this, 'key')));
    },
    unknownProperty(key) {
      let r /*: RelayDef */ = this;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      return r.changeset._valueFor(`${r.key}.${key}`);
    },
    setUnknownProperty(key, value) {
      let r /*: RelayDef */ = this;
      r._changedKeys[key] = null;
      if (!r.changeset) throw new Error('Relay has no changeset.');
      r.changeset._validateAndSet(`${r.key}.${key}`, value);
      r.notifyPropertyChange(key);
      return value;
    },
    rollback() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      for (let k of Object.keys(r._changedKeys)) r.notifyPropertyChange(k);
      r._changedKeys = {};
    },
    destroy() {
      let r /*: RelayDef */ = this;
      r._super(...arguments);
      r.changeset = null;
      r.content = null;
    },
    isEqual(other) {
      let r /*: RelayDef */ = this;
      let original = (0, _object.get)(r, 'content');
      return r === other || original === other;
    }
  } /*: RelayDef */);
});