define("ember-changeset/utils/take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = take;
  // @flow

  function take(originalObj /*: Object */ = {}, keysToTake /*: Array<string> */ = []) {
    let newObj = {};
    for (let key in originalObj) {
      if (keysToTake.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    }
    return newObj;
  }
});