define("ember-changeset/utils/computed/transform", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;
  // @flow

  /*::
  import type Change from 'ember-changeset/-private/change';
  import type Err from 'ember-changeset/-private/err';
  */

  const {
    keys
  } = Object;

  /**
   * Transform an Object's values with a `transform` function.
   */
  function transform /*:: <T> */(dependentKey /*: string       */, transform /*: (T) => mixed */) /*: Object */{
    return (0, _object.computed)(dependentKey, function () {
      let obj /*: Object */ = (0, _object.get)(this, dependentKey);
      return keys(obj).reduce((newObj, key) => {
        newObj[key] = transform(obj[key]);
        return newObj;
      }, {});
    });
  }
});