define("ember-changeset/utils/merge-nested", ["exports", "ember-changeset/utils/set-nested-property"], function (_exports, _setNestedProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeNested;
  // @flow

  const {
    keys
  } = Object;

  /**
   * Given an array of objects, merge their keys into a new object and
   * return the new object.
   *
   * This function merges using `setNestedProperty`.
   */
  function mergeNested /*:: <T> */(...objects /*: Array<{ [string]: T }> */) /*: { [string]: T } */{
    let finalObj = {};
    objects.forEach(obj => keys(obj).forEach(key => (0, _setNestedProperty.default)(finalObj, key, obj[key])));
    return finalObj;
  }
});